<template>
	<div>
		<h1 class="text-center">
			404	Not Found
		</h1>
	</div>
</template>

<script>
export default {
	page: {
		title: '404',
		meta: [{ name: 'description', content: '404' }]
	}
}
</script>
